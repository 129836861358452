<template>
  <div class="hello" style="padding: 0 55px;">
    <div class="info" v-if="!list">
      <div class="top">
        <img class="icon" src="../static/tubiao.png" alt="" />
        <div class="title">体育电子模拟考试成绩查询</div>
      </div>
      <div class="form">
        <div class="formbox">
          <div class="box">
            <span>姓名：</span>
            <input
              v-model="stu_info.name"
              placeholder="请输入姓名"
              type="text"
            />
          </div>
          <div class="box">
            <span>学号：</span>
            <input
              v-model="stu_info.number"
              placeholder="请输入学号"
              type="text"
            />
          </div>
        </div>
        <button type="submit" @click="submit_stuInfo">点击查询</button>
      </div>
    </div>

    <!-- 成绩 -->
    <div class="sorce" v-else>
      <div class="top">
        <img class="icon" src="../static/tubiao.png" alt="" />
        <div class="title">体育电子模拟考试成绩查询</div>
      </div>
      <ul class="form_list">
        <li class="form_item">
          <div class="name">姓名</div>
          <div class="stu_sorce" style="font-size: 12px">
            {{ list.xingming }}
          </div>
        </li>
        <li class="form_item">
          <div class="name">学号</div>
          <div class="stu_sorce" style="font-size: 12px">{{ list.number }}</div>
        </li>
        <li class="form_item">
          <div class="name">800米</div>
          <div class="stu_sorce" style="font-size: 12px">{{ list.ba }}</div>
        </li>
        <li class="form_item">
          <div class="name">50米</div>
          <div class="stu_sorce" style="font-size: 12px">{{list.pao}}</div>
        </li>
        <li class="form_item">
          <div class="name">1000米</div>
          <div class="stu_sorce" style="font-size: 12px">{{ list.yiqian }}</div>
        </li>
        <li class="form_item">
          <div class="name">足球绕杆</div>
          <div class="stu_sorce" style="font-size: 12px">{{ list.zuqiu }}</div>
        </li>
        <li class="form_item">
          <div class="name">一分钟跳绳</div>
          <div class="stu_sorce" style="font-size: 12px">{{ list.tiao }}</div>
        </li>
        <li class="form_item">
          <div class="name">篮球绕干</div>
          <div class="stu_sorce" style="font-size: 12px">{{ list.lan }}</div>
        </li>
        <li class="form_item">
          <div class="name">立定跳远</div>
          <div class="stu_sorce" style="font-size: 12px">{{ list.liding }}</div>
        </li>
        <li class="form_item">
          <div class="name">排球垫球</div>
          <div class="stu_sorce" style="font-size: 12px">{{ list.pai }}</div>
        </li>
        <li class="form_item">
          <div class="name">引体向上</div>
          <div class="stu_sorce" style="font-size: 12px">{{ list.yinti }}</div>
        </li>
        <li class="form_item">
          <div class="name">实心球</div>
          <div class="stu_sorce" style="font-size: 12px">{{ list.shi }}</div>
        </li>
        <li class="form_item">
          <div class="name">仰卧起坐</div>
          <div class="stu_sorce" style="font-size: 12px">{{ list.yang }}</div>
        </li>
        <li class="form_item">
          <div class="name">一分钟跳绳</div>
          <div class="stu_sorce" style="font-size: 12px">{{ list.tiao }}</div>
        </li>
        <li class="form_item">
          <div class="name">立定投铅球</div>
          <div class="stu_sorce" style="font-size: 12px">{{ list.qian }}</div>
        </li>
        <li class="form_item">
          <div class="name">立定三级跳</div>
          <div class="stu_sorce" style="font-size: 12px">{{ list.liding }}</div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { loginUser } from '@/api/login'
export default {
  data(){
    return {
      stu_info:{
        name:"",
        number:""
      },
      list:null
    }
  },
  name: 'HelloWorld',
  props: {
    msg: String
  },
  methods:{
  
  async  submit_stuInfo(){

      const a = await loginUser(this.stu_info)
      if(a.data.code == 1){
        this.list = a.data.data
        console.log(this.list,666);
      }else{
        console.log(a);
          alert(a.data.msg)
      }


    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.hello {
  width: 375px;
  height: 800px;
  background-image: url(../static/bb.jpg);
  background-size: cover;
}
.top {
  display: flex;
}
.title {
  color: #fff;
  margin-top: 45px;
  margin-left: 30px;
}
.icon {
  margin-top: 20px;
  margin-left: 20px;
  width: 70px;
  height: 70px;
  border-radius: 50%;
}
input {
  border: none;
  outline: none;
}
.form {
  width: 350px;
  height: 200px;
  border: 1px solid #fff;
  border-radius: 16px;
  margin-top: 150px;
}
.box {
  height: 50px;
  line-height: 100rpx;
  color: #fff;
  margin-left: 20px;
  margin-top: 20px;
}
button {
  width: 100px;
  height: 30px;
  border-radius: 20rpx;
  background: #859eff;
  color: #fff;
  margin-left: 100px;
}
ul li {
  list-style: none;
  margin: 0;
  padding: 0;
}
.form_list {
  margin-top: 30px;
  /* margin-left:50px; */
}
.form_item {
  display: flex;
}
.name,
.stu_sorce {
  width: 150px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  border: 1px solid #fff;
  color: #fff;
}
</style>
